import { ButtonLoader, ErrorMessage, StateInput } from "components/UI";
import { InputErrors, Order, validateEmail } from "utils";
import { useState } from "react";
import TermsOfUse from "components/TermsOfUse/TermsOfUse";
import Promo from "./Promo/Promo";

import "./CheckoutForm.scss";

export type CheckoutFormProps = {
  formError?: string | null;
  highlightError: boolean;
  formLoading?: boolean;
  handlePaymentClick: () => void;
  handleEmailEdit: (newEmail: string) => void;
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;
};

const CheckoutForm = (props: CheckoutFormProps) => {
  const [showTerms, setShowTerms] = useState(false);

  const handleEmailEdit = (value: string | undefined) => {
    props.handleEmailEdit(value || "");
  };

  return (
    <>
      <div className="checkout-form">
        <div>Nurodykite el. paštą, kuriuo bus išsiųstos indulgencijos</div>
        <StateInput
          name={"El. Paštas"}
          max_length={250}
          onBlur={handleEmailEdit}
          value={props.order.email}
          validations={[{ Function: validateEmail, ErrorMessage: InputErrors.EMPTY_EMAIL }]}
          forceValidation={props.highlightError}
          autoComplete="email"
          type="email"
        ></StateInput>
        <div className="checkout-form-pay">
          <ButtonLoader isLoading={props.formLoading} onClick={props.handlePaymentClick}>
            Mokėti su Paysera
          </ButtonLoader>
          <div className="checkout-form-total">
            Viso: <span className="accent">€{props.order.total.toFixed(2)}</span>
          </div>
        </div>
        <ErrorMessage errorMessage={props.formError} />
        <p className="checkout-terms">
          Spausdami mygtuką&nbsp;<b>MOKĖTI SU PAYSERA</b>&nbsp;Jūs sutinkate su &nbsp;
          <span
            className="accent-hover"
            onClick={() => {
              setShowTerms(!showTerms);
            }}
          >
            pirkimo sąlygomis
          </span>
          .
        </p>
        <Promo order={props.order} setOrder={props.setOrder} />
        {showTerms && (
          <div className="checkout-form-terms">
            <TermsOfUse />
          </div>
        )}
      </div>
    </>
  );
};

export default CheckoutForm;
