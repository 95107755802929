import "./SuccessMessage.scss";

type SuccessMessageProps = {
  message?: string;
};

const SuccessMessage = (props: SuccessMessageProps) => {
  return <div className="success-message">{props.message}</div>;
};

export default SuccessMessage;
