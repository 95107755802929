import React, { useState } from "react";
import "./Input.scss";

export type InputProps = {
  name: string;
  value?: string;
  errorText: string;
  formError: boolean;
  validationFunc?: (input: string) => boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  type?: string;
  children?: any;
  autoComplete?: string;
};

const Input = React.forwardRef((props: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
  const [isError, setIsError] = useState(false);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.validationFunc) {
      let validationResult = props.validationFunc(e.currentTarget.value);
      setIsError(!validationResult);
    }

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleChange = (e: any) => {
    setIsError(false);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <>
      <div className="input">
        <input
          placeholder=" "
          autoComplete={props.autoComplete}
          id={props.name}
          type={props.type}
          ref={ref}
          className={isError || props.formError ? "input-error" : ""}
          onBlur={handleOnBlur}
          onChange={handleChange}
          value={props.value}
        />
        <label htmlFor={props.name}>{props.name}</label>
        {props.children}
      </div>

      {(isError || props.formError) && <div className="error-message">{props.errorText}</div>}
    </>
  );
});

export default Input;
