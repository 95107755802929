// API configuration (base urls, endpoints, app paths)
export const config = {
  API_BASE_URL_DEV: "https://api.sielosvalymas.lt/",
  API_BASE_URL_PROD: "https://api.sielosvalymas.lt/",
  API_ENDPOINTS: {
    INQUIRY: "api/soul-cleansing/inquiry/",
    INDULGENCE_LIST: "api/soul-cleansing/indulgence/",
    ORDER_CREATE: "api/soul-cleansing/order/",
    ORDER_GET_UPDATE: (orderID: string) => `api/soul-cleansing/order/${orderID}/`,
    ORDER_CANCEL: (orderID: string) => `api/soul-cleansing/order/${orderID}/cancel/`,
    ORDER_PAYMENT: (orderID: string) => `api/soul-cleansing/order/${orderID}/payment/`,
    ORDER_ITEM_CREATE: (orderID: string) => `api/soul-cleansing/order/${orderID}/items/`,
    ORDER_ITEM_UPDATE_DELETE: (orderID: string, itemID: string | number) =>
      `api/soul-cleansing/order/${orderID}/items/${itemID}/`,
    ORDER_PROMO_CHECK: (orderID: string, promoCode: string) =>
      `api/soul-cleansing/order/${orderID}/promo/${promoCode}/`,
  },
  APP_PATHS: {
    MAIN: "/",
    BASKET: "/basket",
    ORDER_CANCELLED: "/order/cancelled",
    ORDER_ACCEPTED: "/order/accepted",
    TERMS: "/terms",
    ERROR: "/error",
  },
  LOG_ERRORS: false,
  AUTO_REDIRECT_500: true,
};
