import { OrderItem, InputErrors, validateInputNotEmpty } from "utils";
import { StateInput } from "components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import demo from "../../../assets/images/demo.png";
import Zoom from "react-medium-image-zoom";
import CustomZoomContent from "./CustomZoomContent/CustomZoomContent";

import "react-medium-image-zoom/dist/styles.css";
import "./CheckoutItem.scss";

export type CheckoutItemProps = {
  basketItem: OrderItem;
  highlightError: boolean;
  removeItemClick: (item: OrderItem) => void;
  handleRecipientEdit: (itemID: number, newName: string) => void;
};

const CheckoutItem = (props: CheckoutItemProps) => {
  function createMarkup() {
    return { __html: props.basketItem.indulgence.logo };
  }

  const handleRemoveIconClick = () => {
    props.removeItemClick(props.basketItem);
  };

  const handleRecipientEdit = (value: string | undefined) => {
    props.handleRecipientEdit(props.basketItem.id, value || "");
  };

  return (
    <>
      <div className="checkout-item">
        <div className="checkout-item-top">
          <div className="indulgence-logo">
            <div dangerouslySetInnerHTML={createMarkup()}></div>
          </div>
          <div className="checkout-item-description">
            <h2>{props.basketItem.indulgence.name}</h2>
            <p>{props.basketItem.indulgence.short_description}</p>
          </div>
          <div className="checkout-item-preview" title="Pavyzdys">
            <Zoom ZoomContent={CustomZoomContent}>
              <img width={"100%"} src={demo} alt="indulgence demo" />
            </Zoom>
          </div>
        </div>
        <div className="checkout-item-bottom">
          <div className="checkout-item-recipient">
            <StateInput
              name={"Indulgencijos Gavėja/s"}
              onBlur={handleRecipientEdit}
              max_length={30}
              validations={[{ Function: validateInputNotEmpty, ErrorMessage: InputErrors.EMPTY_RECIPIENT }]}
              value={props.basketItem.recipient}
              forceValidation={props.highlightError}
              autoComplete="off"
            ></StateInput>
          </div>
          <div className="checkout-item-price">€{props.basketItem.indulgence.price.toFixed(2)}</div>
        </div>
        <FontAwesomeIcon
          title="Išimti iš krepšelio"
          onClick={handleRemoveIconClick}
          icon={icon({ name: "trash" })}
          className="checkout-item-x"
        />
      </div>
    </>
  );
};

export default CheckoutItem;
