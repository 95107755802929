import Logo from "components/Logo/Logo";

import "./Top.scss";

const Top = () => {
  return (
    <>
      <div className="top">
        <div className="main-logo">
          <Logo />
        </div>
      </div>
    </>
  );
};

export default Top;
