import "./TermsOfUse.scss";

const TermsOfUse = () => {
  return (
    <div className="terms">
      <b>ĮVADAS IR APIMTIS</b>
      <br />
      Sveiki atvykę į "Sielos Valymas" elektroninę parduotuvę. Mes esame paslaugų teikėjai, specializuojantys
      "indulgencijų" paslaugose. Pirkdami mūsų siūlomas paslaugas, Jūs sutinkate su šiomis taisyklėmis ir sąlygomis.
      Skaitant toliau esančias taisykles, "Sielos Valymas" bus vadintas "Mūsų parduotuve", o "indulgencija" - mūsų
      teikiamu paslaugu raštu. APIBRĖŽIMAI
      <ul>
        <li>
          Indulgencija: Raštas, aprašantis gyvenimiškas situacijas ir atleidimą nuo jų, teikiamas elektronine forma kaip
          paslauga.
        </li>
        <li>Mūsų parduotuvė: "Sielos Valymas", el. paštas: sielosvalymas@outlook.com.</li>
        <li>Pirkėjas: Asmuo, perkantis "indulgenciją" iš "Mūsų parduotuvės".</li>
        <li>Paysera: Trečioji šalis, atsakinga už mokėjimų tvarkymą.</li>
      </ul>
      <span className="accent">Dėkojame</span>, kad pasirinkote "Sielos Valymo" paslaugas. Pirkdami "indulgenciją",
      sutinkate su šiomis sąlygomis. Jūsų patogumui prašome atidžiai perskaityti visas taisykles.
      <br />
      <br />
      <b>PARDAVIMO SĄLYGOS</b>
      <br />
      Informuojame, kad sąskaitos informacijos ir mokėjimo inicijavimo paslaugas Jums suteiks Paysera vadovaudamasi šių paslaugų teikimo <a href="https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020" className="accent" target="_blank" rel="noopener noreferrer">taisyklėmis</a>. Tęsdami apmokėjimą patvirtinate, kad sutinkate su šių paslaugų suteikimu ir jų teikimo sąlygomis.
      <ol>
        <li>Užsakymo Procedūra:</li>
        <ul>
          <li>1.1 Pasirinkite norimą "indulgencijos" rūšį ir pridėkite ją į savo krepšelį.</li>
          <li>1.2 Toliau eikite į mokėjimo langą ir sekti Paysera instrukcijas.</li>
          <li>1.3 Patvirtinus mokėjimą, į nurodytą el. paštą gausite "indulgencijos" patvirtinimo laišką.</li>
        </ul>
        <li>
          Atšaukimas ir Grąžinimas: Kadangi "indulgencija" teikiama elektroniniu būdu, jokios grąžinimo ar atšaukimo
          galimybės nėra.
        </li>
        <li>
          Sąskaitos faktūra: Jeigu reikia sąskaitos faktūros, prašome susisiekti el. paštu: sielosvalymas@outlook.com.
        </li>
        <li>
          Mokėjimo Patvirtinimas: Mokėjimo patvirtinimas bus išsiųstas iš Paysera paslaugų ir bus pateiktas į nurodytą
          el. paštą.
        </li>
        <li>
          Indulgencijos Pateikimas: Pirkę "indulgenciją", šis raštas bus išsiųstas į nurodytą el. paštą. Papildomo
          fizinio ar kitokio pristatymo nėra.
        </li>
        <li>
          Privatumas: Saugome tik Jūsų pateiktą el. paštą ir jį naudojame "indulgencijos" siuntimui. Kitų duomenų
          nerenkame.
        </li>
        <li>
          Kreipimasis Dėl Pagalbos: Turite klausimų ar pageidavimų? Kreipkitės el. paštu: sielosvalymas@outlook.com.
        </li>
      </ol>
      <br />
      <b>PRISTATYMAS</b>
      <ol>
        <li>
          Elektroninio Pristatymo Procedūra: "Indulgencija" pristatoma elektroniniu būdu į nurodytą el. paštą po
          sėkmingo apmokėjimo.{" "}
        </li>
        <li>
          Pristatymo Laikotarpis: Jeigu raštas su "indulgencija" nebuvo išsiųstas per 24 valandas galite kreiptis paštu
          sielosvalymas@outlook.com.
        </li>
        <li>
          Papildomo Pristatymo Mokesčio Nėra: "Indulgencijos" pristatymo į nurodytą el. paštą neapmokama papildomu
          mokesčiu.
        </li>
        <li>
          Pristatymo Klaidos: Jeigu pasitaiko pristatymo klaida ar užtrukimas, prašome susisiekti el. paštu:
          sielosvalymas@outlook.com.
        </li>
        <li>
          Nurodyto El. Pašto Tikslumas: Užtikrinkite, kad nurodytas el. paštas būtų teisingas, kad būtų išvengta
          pristatymo klaidų.
        </li>
      </ol>
      <br />
      <b>GRĄŽINIMAS IR KEITIMAS</b>
      <ol>
        <li>
          Elektroninių Produktų Specifika: Elektroniniai "indulgencijos" raštai nėra grąžinami ar keičiami dėl jų
          pobūdžio.
        </li>
        <li>
          Kokybės Garantija: Visi pateikti "indulgencijos" raštai yra kruopščiai tikrinti ir užtikrinama jų kokybė.
        </li>
        <li>Pirkėjo Atsakomybė: Pirkėjas prisiima atsakomybę už teisingai įvedamą el. paštą ir teisingą mokėjimą.</li>
        <li>
          Nenumatytų Situacijų Atvejai: Nenumatytiems atvejams, susijusiems su grąžinimu ar keitimu, susisiekite el.
          paštu: sielosvalymas@outlook.com.
        </li>
        <li>
          Sąskaitos Faktūros Pateikimas: Papildoma sąskaita faktūra pateikiama tik prašant el. paštu:
          sielosvalymas@outlook.com.
        </li>
      </ol>
      <br />
      <b>PRIVATUMO POLITIKA</b>
      <ol>
        <li>
          Asmeninės Informacijos Saugumas: Mes gerbiame jūsų privatumą. Pateikdami el. paštą mums, jūs sutinkate, kad
          jis bus naudojamas tik "Sielos Valymo" paslaugoms.
        </li>
        <li>Duomenų Saugumas: Jūsų suteikti duomenys saugomi šifruotu būdu ir neprieinami tretiesiems asmenims.</li>
        <li>Duomenų Naudojimas: Jūsų el. paštas naudojamas tik "Sielos Valymo" paslaugoms ir informacijai siųsti.</li>
        <li>
          Duomenų Perdavimas: Mes niekada nesidalinsime jūsų pateikta informacija su tretiesiems asmenims be jūsų
          sutikimo.
        </li>
        <li>El. Pašto Prenumeratos: Jūs turėsite galimybę pasirinkti arba nutraukti mūsų naujienlaiškių gavimą.</li>
        <li>
          Privatumo Politikos Pakeitimai: Mes galime atnaujinti privatumo politiką, informuosime jus apie bet kokius
          pakeitimus.
        </li>
        <li>
          Klientų Aptarnavimas: Bet kokios susirūpinimą keliančios situacijos, susijusios su privatumu, prašome
          pranešti: sielosvalymas@outlook.com.
        </li>
      </ol>
      <br />
      <b>SAUGUMAS</b>
      <ol>
        <li>
          Duomenų Saugumas: Jūsų teikti duomenys, įskaitant el. paštą, saugomi aukšto lygio šifravimu ir yra neprieinami
          tretiesiems asmenims.
        </li>
        <li>
          Mokėjimo Saugumas: Mokant per Paysera, naudojamas jų užtikrinimas ir šifruotas mokėjimas, užtikrinantis jūsų
          finansinį saugumą.
        </li>
        <li>
          Puslapio Saugumas: Mūsų svetainė naudoja šiuolaikines saugumo priemones, užtikrinančias jūsų naršymo saugumą.
        </li>
        <li>
          Piktnaudžiavimo Prevencija: Mes įsipareigojame prevencijai nuo piktnaudžiavimo jūsų pateikta informacija.
        </li>
        <li>
          Nuolatinis Atnaujinimas: Saugumo priemonės nuolat atnaujinamos, kad būtų užtikrintas aukštas duomenų apsaugos
          lygis.
        </li>
        <li>
          El. Pašto Saugumas: Visos el. pašto kampanijos yra saugios ir nesujungiamos su kenkėjiškomis programomis.
        </li>
        <li>
          Nusiskundimai ir Apsauga: Bet kokie įtarimai dėl saugumo pažeidimų turėtų būti pranešami:
          sielosvalymas@outlook.com.
        </li>
      </ol>
      <br />
      <b>ATSAKOMYBĖ IR GARANTIJOS</b>
      <ol>
        <li>
          Paslaugų Teikimo Garantijos: Mes įsipareigojame teikti aukščiausios kokybės paslaugas, atitinkančias jūsų
          lūkesčius.
        </li>
        <li>
          Elektroninių Raštų Savybės: Elektroniniai raštai yra unikalūs ir pagaminti pagal jūsų nurodymus. Mes
          garantuojame jų originalumą.
        </li>
        <li>
          Sąžinės Ramybė: Elektroniniais raštais nesuteikiame tikros sąžinės ramybės. Tai yra humoro ir netikrų istorijų
          produktas.
        </li>
        <li>
          Nesuderinamumas su Tikrove: Mes neprisiimame atsakomybės už tai, jei elektroniniais raštais perteikiamos
          situacijos nesuderina su realybe ar sukelia nesusipratimus.
        </li>
        <li>
          Pirkėjo Atsakomybė: Pirkėjas yra atsakingas už teisingai pateiktus duomenis ir už elektroninio rašto paskirtį.
        </li>
        <li>
          Privatumas: Mes saugome jūsų pateiktus duomenis, bet neprisiimame atsakomybės už bet kokią trečiųjų asmenų
          įsilaužimo veiklą.
        </li>
        <li>Paslaugų Pakeitimas: Mes negarantuojame elektroninio rašto pakeitimo ar grąžinimo po jo įsigijimo.</li>
        <li>
          Kliento Aptarnavimas: Mes įsipareigojame suteikti tinkamą klientų aptarnavimą ir atsakyti į jūsų klausimus bei
          pageidavimus.
        </li>
      </ol>
      <br />
      <b>ATSAKOMYBĖ IR GARANTIJOS</b>
      <ol>
        <li>
          Paslaugų Teikimo Garantijos: Mes įsipareigojame teikti aukščiausios kokybės paslaugas, atitinkančias jūsų
          lūkesčius.
        </li>
        <li>
          Elektroninių Raštų Savybės: Elektroniniai raštai yra unikalūs ir pagaminti pagal jūsų nurodymus. Mes
          garantuojame jų originalumą.
        </li>
        <li>
          Sąžinės Ramybė: Elektroniniais raštais nesuteikiame tikros sąžinės ramybės. Tai yra humoro ir netikrų istorijų
          produktas.
        </li>
        <li>
          Nesuderinamumas su Tikrove: Mes neprisiimame atsakomybės už tai, jei elektroniniais raštais perteikiamos
          situacijos nesuderina su realybe ar sukelia nesusipratimus.
        </li>
        <li>
          Pirkėjo Atsakomybė: Pirkėjas yra atsakingas už teisingai pateiktus duomenis ir už elektroninio rašto paskirtį.
        </li>
        <li>
          Privatumas: Mes saugome jūsų pateiktus duomenis, bet neprisiimame atsakomybės už bet kokią trečiųjų asmenų
          įsilaužimo veiklą.
        </li>
        <li>Paslaugų Pakeitimas: Mes negarantuojame elektroninio rašto pakeitimo ar grąžinimo po jo įsigijimo.</li>
        <li>
          Kliento Aptarnavimas: Mes įsipareigojame suteikti tinkamą klientų aptarnavimą ir atsakyti į jūsų klausimus bei
          pageidavimus.
        </li>
      </ol>
      <br />
      <b>Pirkėjo Atsakomybės</b>
      <ol>
        <li>
          Pirkimo proceso pradžia: Pirkėjas įsipareigoja teikti tikslų ir taisyklingą informaciją pirkimo metu,
          įskaitant teisingus kontaktinius duomenis.
        </li>
        <li>
          Mokėjimas: Pirkėjas įsipareigoja užtikrinti sklandų mokėjimą už paslaugas naudojantis nurodytu mokėjimo būdu.
        </li>
        <li>
          Pirkėjo teisės: Pirkėjas turi teisę laisvai naudotis svetaine, bet jis privalo laikytis šių taisyklių ir
          sąlygų.
        </li>
        <li>
          Atšaukimas ir grąžinimas: Pirkėjas supranta, kad nusipirktos paslaugos yra negrįžtamos ir negalioja atšaukimo
          arba grąžinimo galimybė. Atveju, jei kyla techninės problemos arba kiti susitarimui nepriklausantys trūkumai,
          prašome susisiekti su mumis.
        </li>
        <li>
          Privatumas: Pirkėjas yra atsakingas už savo paskyros duomenų saugumą ir turi pranešti apie bet kokią įtartį
          dėl paskyros naudojimo be leidimo.
        </li>
        <li>
          Konfliktai: Pirkėjas įsipareigoja bendradarbiauti ir ieškoti konstruktyvių sprendimų, jei kyla nesusipratimų
          arba konfliktų.
        </li>
      </ol>
      <br />
      <b>Kontaktai</b>
      <ul>
        <li>
          Kreipimasis į mus: Pirkėjas gali bet kada kreiptis į mus el. paštu adresu sielosvalymas@outlook.com. Mūsų
          komanda stengsis atsakyti į visus klausimus ir suteikti reikalingą informaciją.
        </li>
        <li>
          Skundų pateikimas: Pirkėjas, kuris susiduria su problemomis ar netenkintas teikiamomis paslaugomis, turi teisę
          pateikti skundą.
        </li>
        <li>Socialiniai tinklai: sielosvalymas.lt, <a className="accent" href="https://facebook.com/sielosvalymas">facebook.com/sielosvalymas</a></li>
        <li>Kūrėjas: Dominykas Stumbras</li>
        <li>Tel nr: +370 61932434</li>
        <li>El paštas:  sielosvalymas@outlook.com</li>
      </ul>
    </div>
  );
};

export default TermsOfUse;
