import "./Faq.scss";
import FaqQuestion from "./FaqQuestion/FaqQuestion";

const Faq = () => {
  return (
    <section id="faq" className="section">
      <div className="container">
        <h2 className="faq-h2">Dažnai užduodami klausimai</h2>
        <div className="tabs">
          <FaqQuestion question="Kas yra indulgencija?">
            <span className="accent">Indulgencija</span> - tai sąžinės ramybės sertifikatas, nuodėmių išpirka arba
            tiesiog galimybė pradėti iš naujo be sąžinės graužaties.
          </FaqQuestion>
          <FaqQuestion question="Ar galiu padovanoti indulgenciją kažkam kitam?">
            Taip! Indulgencijos sertifikatas, tai greita ir smagi dovana. Perkant indulgenciją, yra nurodomas
            sertifikatos gavėjo vardas.
          </FaqQuestion>
          <FaqQuestion question="Kaip atrodo gautas sertifikatas?">
            Sertifikatas išduodamas elektronine forma, jame yra paskelbtas indulgencijos <b>gavėjas</b>, jam atperkamos
            <b> nuodėmės pavadinimas</b> ir <b>štampas</b>. Taip pat yra pridedamas detalus indulgencijos <b>aprašas</b>
            , kuris ne tik paaiškina nuodėmės būdą, bet ir priverčia sąžinės neturėtoją nusišypsoti.
          </FaqQuestion>
          <FaqQuestion question="Kada gausiu sertifikatą?">
            Sertifikatas yra atsiunčiamas nurodytu el. paštu vos po sėkmingo nusipirkimo. Jeigu sertifikato negavote,
            patikrinkite <b>spam</b> katalogą savo pašte arba susisiekite
            <span className="accent"> sielosvalymas@outlook.com</span> ir mes jums padėsime.
          </FaqQuestion>
          <FaqQuestion question="Kiek galioja sertifikatas?">Sertifikatas galioja metus nuo pirkimo datos.</FaqQuestion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
