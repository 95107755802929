import { Api, useApiCall } from "api";
import { deleteAllUrlParams, getUrlParam } from "utils";

import { LinkIndulgences, TopSmall } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./Cancel.scss";

const Cancel = () => {
  const { makeRequest: CancelPayment } = useApiCall<null>();
  const param = getUrlParam("order_id");

  if (param && param !== "") {
    CancelPayment(Api.ENDPOINTS.ORDER_CANCEL(param), {}, { is_payment: true }, Api.METHODS.POST);
    deleteAllUrlParams();
  }

  return (
    <>
      <TopSmall />
      <div className="container-card">
        <div className="cancel-header">
          <h1>Jūsų užsakymas buvo atšauktas</h1>
          <FontAwesomeIcon title="Ok" icon={icon({ name: "x" })} className="x-icon" />
        </div>
        <LinkIndulgences />
      </div>
    </>
  );
};

export default Cancel;
