import LinkIndulgences from "components/LinkIndulgences/LinkIndulgences";

import "./EmptyBasket.scss";

const EmptyBasket = () => {
  return (
    <>
      <div className="container-card">
        <h1 className="empty-basket-header">Krepšelis tuščias</h1>
        <LinkIndulgences />
      </div>
    </>
  );
};

export default EmptyBasket;
