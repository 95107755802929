import { CheckoutList, TopSmall } from "components";

import "./Checkout.scss";

const Checkout = () => {
  return (
    <>
      <TopSmall />
      <CheckoutList />
    </>
  );
};

export default Checkout;
