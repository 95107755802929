import { clearBasket, useAppDispatch } from "state";
import { EmptyBasket, Loader } from "components";
import { useEffect, useState } from "react";
import {
  GetPaymentResponse,
  Order,
  OrderItem,
  STORAGE_KEYS,
  getFromLocalStorage,
  removeFromLocalStorage,
  validateEmail,
  validateInputNotEmpty,
} from "utils";
import { Api, config, useApiCall } from "api";
import CheckoutItem from "./CheckoutItem/CheckoutItem";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import { useNavigate } from "react-router-dom";

import "./CheckoutList.scss";

const CheckoutList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<Order | null>(null);
  const [showOrderError, setShowOrderError] = useState(false);
  const orderID = getFromLocalStorage(STORAGE_KEYS.BASKET_ID);

  const {
    makeRequest: getOrder,
    loading: orderLoading,
    response: orderResponse,
    error: orderError,
  } = useApiCall<Order>();
  const { makeRequest: updateOrder } = useApiCall<null>();
  const { makeRequest: cancelOrder, response: cancelResponse } = useApiCall<null>();
  const { makeRequest: removeOrderItem, response: itemRemoveResponse } = useApiCall<null>();
  const { makeRequest: updateOrderItem } = useApiCall<null>();
  const {
    makeRequest: getPayment,
    loading: paymentLoading,
    response: paymentResponse,
    error: paymentError,
  } = useApiCall<GetPaymentResponse>();

  // Initial
  useEffect(() => {
    if (orderID === null || orderID === "") {
      return;
    }
    getOrder(Api.ENDPOINTS.ORDER_GET_UPDATE(orderID));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // Order GET
  useEffect(() => {
    if (orderID === null || orderID === "") {
      return;
    }
    if (!orderResponse && !orderError) {
      return;
    }

    if (orderError) {
      removeFromLocalStorage(STORAGE_KEYS.BASKET_ID);
      dispatch(clearBasket());
      navigate(config.APP_PATHS.MAIN);
      return;
    }

    if (orderResponse) {
      setOrder(orderResponse.data);
      return;
    }

    navigate(config.APP_PATHS.MAIN);
  }, [orderResponse, orderError]); //eslint-disable-line react-hooks/exhaustive-deps

  // OrderItem DELETE
  useEffect(() => {
    if (orderID === null || orderID === "") {
      return;
    }
    if (itemRemoveResponse && itemRemoveResponse.status !== 204) {
      getOrder(Api.ENDPOINTS.ORDER_GET_UPDATE(orderID));
    }
  }, [itemRemoveResponse]); //eslint-disable-line react-hooks/exhaustive-deps

  // Payment URL GET
  useEffect(() => {
    if (orderID === null || orderID === "") {
      return;
    }
    if (paymentResponse?.status === 200) {
      removeFromLocalStorage(STORAGE_KEYS.BASKET_ID);
      window.location.href = paymentResponse.data.payment_url;
    }
  }, [paymentResponse, paymentError]); //eslint-disable-line react-hooks/exhaustive-deps

  // Order Cancel POST
  useEffect(() => {
    if (orderID === null || orderID === "") {
      return;
    }
    if (cancelResponse && cancelResponse.status) {
      removeFromLocalStorage(STORAGE_KEYS.BASKET_ID);
      dispatch(clearBasket());
      setOrder(null);
    }
  }, [cancelResponse]); //eslint-disable-line react-hooks/exhaustive-deps

  // CheckoutList component checks
  if (orderID === null || orderID === "") {
    return <EmptyBasket />;
  }
  if (order === null || orderLoading || orderResponse === null) {
    return (
      <div className="loader-container">
        <Loader size="100%" />
      </div>
    );
  }

  const formLoading = paymentLoading || paymentResponse?.status === 200;

  const validateOrder = () => {
    if (validateEmail(order.email) === false) {
      return false;
    }
    for (const item of order.items) {
      if (validateInputNotEmpty(item.recipient) === false) {
        return false;
      }
    }
    return true;
  };

  // Handle clicks
  const handlePaymentClick = () => {
    if (validateOrder() === false) {
      setShowOrderError(true);
      return;
    }
    const finalOrder = {
      id: order.id,
      email: order.email,
      items: order.items.map((item) => ({
        id: item.id,
        indulgence_id: item.indulgence.id,
        recipient: item.recipient,
      })),
      total: order.total.toFixed(2),
      accept_terms: true,
      promo_code: order.promo,
    };
    getPayment(Api.ENDPOINTS.ORDER_PAYMENT(orderID), {}, finalOrder, Api.METHODS.POST);
  };
  const handleRemoveItemClick = (removedItem: OrderItem) => {
    if (formLoading) {
      return;
    }
    const newOrder: Order = { ...order, promo: undefined };
    newOrder.items = order.items.filter((item) => item.id !== removedItem.id);
    newOrder.total -= removedItem.indulgence.price;
    if (newOrder.items.length === 0) {
      cancelOrder(Api.ENDPOINTS.ORDER_CANCEL(orderID), {}, {}, Api.METHODS.POST);
      return;
    }

    removeOrderItem(Api.ENDPOINTS.ORDER_ITEM_UPDATE_DELETE(orderID, removedItem.id), {}, {}, Api.METHODS.DELETE);
    setOrder(newOrder);
  };
  const handleRecipientEdit = (itemID: number, newName: string) => {
    if (formLoading) {
      return;
    }
    const item = order?.items.find((item) => {
      return item.id === itemID;
    });

    if (item && item.recipient === newName) {
      return;
    }

    updateOrderItem(
      Api.ENDPOINTS.ORDER_ITEM_UPDATE_DELETE(orderID, itemID.toString()),
      {},
      { recipient: newName },
      Api.METHODS.PUT
    );

    const newOrder = { ...order };
    newOrder.items = order.items.map((item) => {
      if (item.id === itemID) {
        item.recipient = newName;
      }
      return item;
    });
    setOrder(newOrder);
  };
  const handleEmailEdit = (newEmail: string) => {
    if (formLoading) {
      return;
    }
    if (validateEmail(newEmail) === false) {
      return;
    }
    updateOrder(Api.ENDPOINTS.ORDER_GET_UPDATE(orderID), {}, { email: newEmail }, Api.METHODS.PUT);
    const newOrder = { ...order, email: newEmail };
    setOrder(newOrder);
  };

  return (
    <>
      <div className="checkout-container">
        <div className="checkout-items">
          {order.items.map((item) => {
            return (
              <CheckoutItem
                highlightError={showOrderError}
                key={item.id}
                basketItem={item}
                removeItemClick={handleRemoveItemClick}
                handleRecipientEdit={handleRecipientEdit}
              />
            );
          })}
        </div>
        <CheckoutForm
          order={order}
          formError={paymentError}
          highlightError={showOrderError}
          handlePaymentClick={handlePaymentClick}
          formLoading={formLoading}
          handleEmailEdit={handleEmailEdit}
          setOrder={setOrder}
        />
      </div>
    </>
  );
};

export default CheckoutList;
