import "./Loader.scss";

export type LoaderProps = {
  size?: string
}

const Loader = (props: LoaderProps) => {
  const sizeStyle = {
    height: props.size ? `${props.size}` : "4rem"
  }

  return (
    <div className="loader" style={sizeStyle}>
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
