import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import { Loader } from "..";
import ListShowLess from "./ListShowLess/ListShowLess";

import "./PaginatedList.scss";

export type PaginatedListProps = {
  children: JSX.Element[];
  onLoadMore: () => void;
  onShowLess: () => void;
  loadMoreLoading: boolean;
  nextPageAvailable: boolean;
};

const PaginatedList = (props: PaginatedListProps) => {
  const paginatedListRef = useRef<HTMLDivElement>(null);
  const [isPaginating, setIsPaginating] = useState<boolean | null>(null);
  const [initialListSize, setInitialListSize] = useState(0);

  const isShowLessVisible = props.children.length > initialListSize;

  const { ref: listTopRef, inView: listTopInView } = useInView({ threshold: 0, skip: !isShowLessVisible });
  const { ref: listRef, inView: listInView } = useInView({ threshold: 0, skip: !isShowLessVisible });
  const { ref: listEndRef, inView: listEndInView } = useInView({ threshold: 0, skip: !isPaginating });
  const { ref: listBottomRef, inView: listBottomInView } = useInView({ threshold: 1, skip: !isShowLessVisible });

  useEffect(() => {
    if (initialListSize === 0 && props.children.length > 0) {
      setInitialListSize(props.children.length);
    }
  }, [props.children]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (listEndInView && isPaginating && props.loadMoreLoading === false) {
      props.onLoadMore();
    }
  }, [listEndInView]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.nextPageAvailable && isPaginating && listEndInView) {
      props.onLoadMore();
    } else if (isPaginating) {
      setIsPaginating(false);
    }
  }, [props.nextPageAvailable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isPaginating === false && initialListSize === props.children.length && paginatedListRef.current) {
      paginatedListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isPaginating, props.children.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowLess = () => {
    setIsPaginating(false);
    props.onShowLess();
  };

  const handleLoadMore = () => {
    setIsPaginating(true);
    props.onLoadMore();
  };

  let nextPageComponent = null;
  if (props.loadMoreLoading) {
    nextPageComponent = <Loader />;
  } else if (props.nextPageAvailable) {
    nextPageComponent = (
      <div className="indulgence-list-load-more-btn centered" onClick={handleLoadMore}>
        Daugiau indulgencijų!
      </div>
    );
  }

  return (
    <>
      <div ref={paginatedListRef}>
        <div ref={listTopRef} className="paginated-list-top"></div>
        <div ref={listRef} className="paginated-list-items">
          {props.children}
        </div>
        <div ref={listEndRef}></div>
        <div ref={listBottomRef} className="indulgence-list-pagination">
          {isShowLessVisible && (
            <ListShowLess
              listTopInView={listTopInView}
              listInView={listInView}
              listBottomInView={listBottomInView}
              onClick={handleShowLess}
            />
          )}
          <div className="indulgence-list-load-more">{nextPageComponent}</div>
        </div>
      </div>
    </>
  );
};

export default PaginatedList;
