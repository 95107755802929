import { Loader } from "components";
import "./ButtonLoader.scss";

type ButtonProps = {
  children?: any;
  onClick?: () => any;
  className?: string;
  isLoading?: boolean;
};

const Button = ({ children, onClick, className, isLoading }: ButtonProps) => {
  return (
    <button
      className={`default-button noselect button-loader ${isLoading ? "button-loader-loading" : ""} ${className}`}
      onClick={onClick}
      type="button"
    >
      {isLoading && (
        <div className="added-icon added">
          <Loader size="100%" />
        </div>
      )}
      {children}
    </button>
  );
};

export default Button;
