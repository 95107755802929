type CustomZoomProps = {
  buttonUnzoom: any;
  modalState: any;
  img: any;
};

const CustomZoomContent = ({ buttonUnzoom, modalState, img }: CustomZoomProps) => {
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <>
      <div
        onTouchStart={(e) => {
          if (mql.matches) {
            e.stopPropagation();
          }
        }}
        onClick={(e) => {
          if (mql.matches) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {img}
      </div>
      {buttonUnzoom}
    </>
  );
};

export default CustomZoomContent;
