import { config } from "api";
import { Link } from "react-router-dom";

import "./LinkIndulgences.scss";

type LinkIndulgencesProps = {
  inverse?: boolean;
};

const LinkIndulgences = (props: LinkIndulgencesProps) => {
  return (
    <>
      <div className={`back-indulgences ${props.inverse ? "back-indulgences-inverse" : ""}`}>
        <Link to={config.APP_PATHS.MAIN}>
          <span>Grįžti į indulgencijų sąrašą</span>
        </Link>
      </div>
    </>
  );
};

export default LinkIndulgences;
