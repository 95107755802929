import { Form, Input } from "components";
import { useEffect, useRef, useState } from "react";
import Textarea from "components/UI/Textarea/Textarea";
import ReCAPTCHA from "react-google-recaptcha";
import { Api, useApiCall } from "api";
import { Inquiry, validateEmail, validateInputNotEmpty } from "utils";
import { InputErrors } from "utils";
import SuccessMessage from "components/UI/SuccessMessage/SuccessMessage";

import "./ContactForm.scss";

const initialErrorState = {
  name: false,
  email: false,
  message: false,
  recaptcha: false,
};

const ContactForm = () => {
  let mql = window.matchMedia("(max-width: 600px)");
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const recaptchaRef = useRef<any>(null);

  const [formErrors, setFormErrors] = useState(initialErrorState);

  const {
    makeRequest: sendMessage,
    loading: messageLoading,
    response: messageResponse,
    error: messageError,
  } = useApiCall();

  useEffect(() => {
    if (messageError === null) {
      formRef.current?.reset();
    }
    recaptchaRef.current.reset();
  }, [messageResponse, messageError]);

  const validateForm = () => {
    let errors = { ...initialErrorState };
    let isValidationOk = true;

    if (validateInputNotEmpty(nameRef.current?.value) === false) {
      errors.name = true;
    }

    if (validateEmail(emailRef.current?.value) === false) {
      errors.email = true;
    }

    if (validateInputNotEmpty(messageRef.current?.value) === false) {
      errors.message = true;
    }

    if (recaptchaRef.current.getValue() === "") {
      errors.recaptcha = true;
    }

    Object.values(errors).forEach((value, index) => {
      if (value === true) {
        isValidationOk = false;
        return;
      }
    });

    setFormErrors(errors);
    return isValidationOk;
  };

  const sendMessageRequest = () => {
    const inquiry: Inquiry = {
      name: nameRef.current?.value,
      email: emailRef.current?.value,
      message: messageRef.current?.value,
      recaptcha_token: recaptchaRef.current.getValue(),
    };

    sendMessage(Api.ENDPOINTS.INQUIRY, {}, inquiry, Api.METHODS.POST);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm() === false) {
      return;
    }

    sendMessageRequest();
  };

  return (
    <>
      <div className="inquiry-form">
        <Form
          ref={formRef}
          onSubmit={handleFormSubmit}
          errorText={messageError && messageError !== "" ? messageError : ""}
          isLoading={messageLoading}
          submitButtonName="Siųsti žinutę"
          childrenAfterSubmitButton={
            messageResponse?.status === 201 && <SuccessMessage message="Ačiū už Jūsų užklausą!" />
          }
        >
          <Input
            name="Vardas"
            ref={nameRef}
            validationFunc={validateInputNotEmpty}
            errorText={InputErrors.EMPTY_NAME}
            formError={formErrors.name}
            onChange={() => {
              setFormErrors({ ...formErrors, name: false });
            }}
            autoComplete="name"
          />

          <Input
            name="El. paštas"
            ref={emailRef}
            type="email"
            validationFunc={validateEmail}
            errorText={InputErrors.INCORRECT_EMAIL}
            formError={formErrors.email}
            onChange={() => {
              setFormErrors({ ...formErrors, email: false });
            }}
            autoComplete="email"
          />

          <Textarea
            name="Žinutė"
            ref={messageRef}
            validationFunc={validateInputNotEmpty}
            errorText={InputErrors.EMPTY_MESSAGE}
            formError={formErrors.message}
            onChange={() => {
              setFormErrors({ ...formErrors, message: false });
            }}
            autoComplete="off"
          />

          <div className="recaptcha-div">
            <ReCAPTCHA
              size={mql.matches ? "compact" : "normal"}
              sitekey="6LdRWXInAAAAAAaANGBO-HlV2gxzSds_wMDLaqSq"
              ref={recaptchaRef}
              onChange={() => {
                setFormErrors({ ...formErrors, recaptcha: false });
              }}
            ></ReCAPTCHA>
            {formErrors.recaptcha === true && <div className="error-message">{InputErrors.RECAPTCHA}</div>}
          </div>
        </Form>
      </div>
    </>
  );
};

export default ContactForm;
