import "./Button.scss";

type ButtonProps = {
  children?: any;
  onClick?: () => any;
  className?: string;
};

const Button = ({ children, onClick, className }: ButtonProps) => {
  return (
    <button className={`default-button noselect ${className}`} onClick={onClick} type="button">
      {children}
    </button>
  );
};

export default Button;
