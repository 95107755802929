import { TermsOfUse, TopSmall } from "components";

const Terms = () => {
  return (
    <>
      <TopSmall />
      <TermsOfUse />
    </>
  );
};

export default Terms;
