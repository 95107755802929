import { LinkIndulgences, TopSmall } from "components";
import { deleteAllUrlParams } from "utils";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Accept.scss";

const Accept = () => {
  deleteAllUrlParams();

  return (
    <>
      <TopSmall />
      <div className="accept container-card">
        <div className="accept-header">
          <h1>Jūsų užsakymas priimtas</h1>
          <FontAwesomeIcon title="Ok" icon={icon({ name: "check" })} className="accept-icon" />
        </div>
        <h2>Kai apmokėjimas bus patvirtintas, Jūsų užsakymas bus atsiųstas nurodytu el. paštu.</h2>
        <h3>
          Patikrinkite <b>spam</b> katalogą, jeigu nerandate laiško.
        </h3>
        <LinkIndulgences />
      </div>
    </>
  );
};

export default Accept;
