import { ReactSVG } from "react-svg";
import logoCol from "assets/icons/logo.svg";

import "./Logo.scss";

type LogoProps = {
  className?: string;
};

const Logo = (props: LogoProps) => {
  return (
    <>
      <div className={`noselect ${props.className || ""}`}>
        <ReactSVG src={logoCol} />
      </div>
    </>
  );
};

export default Logo;
