import { useEffect, useState } from "react";
import { Api, useApiCall } from "api";
import { Loader, PaginatedList } from "components/UI";
import { Indulgence, Paginated } from "utils";
import IndulgenceCard from "./IndulgenceCard/IndulgenceCard";

import "./Indulgences.scss";

const Indulgences = () => {
  const [indulgenceList, setIndulgenceList] = useState<Indulgence[]>([]);
  const [nextPage, setNextPage] = useState("");

  const {
    makeRequest: getIndulgences,
    loading: indulgencesLoading,
    response: indulgencesResponse,
  } = useApiCall<Paginated<Indulgence>>();
  const {
    makeRequest: getIndulgencesNext,
    loading: nextPageLoading,
    response: indulgencesNextResponse,
  } = useApiCall<Paginated<Indulgence>>();

  useEffect(() => {
    getIndulgences(Api.ENDPOINTS.INDULGENCE_LIST);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (indulgencesResponse?.data.results) {
      setIndulgenceList(indulgencesResponse.data.results);
      if (indulgencesResponse.data.next) {
        setNextPage(indulgencesResponse.data.next);
      }
    }
  }, [indulgencesResponse]);

  useEffect(() => {
    if (indulgencesNextResponse?.data.results) {
      setIndulgenceList(indulgenceList.concat(indulgencesNextResponse.data.results));
      if (indulgencesNextResponse.data.next) {
        setNextPage(indulgencesNextResponse.data.next);
      } else {
        setNextPage("");
      }
    }
  }, [indulgencesNextResponse]); //eslint-disable-line react-hooks/exhaustive-deps

  const loadMore = () => {
    if (nextPage) {
      getIndulgencesNext(nextPage);
    }
  };

  const showLess = () => {
    if (indulgencesResponse) {
      setIndulgenceList(indulgencesResponse.data.results);
      if (indulgencesResponse.data.next) {
        setNextPage(indulgencesResponse.data.next);
      }
    }
  };

  if (indulgencesLoading || indulgencesResponse === null || indulgenceList.length === 0) {
    return (
      <section id="indulgences" className="section">
        <Loader />
      </section>
    );
  }

  const indulgenceCards = indulgenceList.map((indulgence: Indulgence) => (
    // TODO memo
    <IndulgenceCard key={indulgence.id} indulgence={indulgence} />
  ));

  return (
    <section id="indulgences" className="section">
      <PaginatedList
        nextPageAvailable={Boolean(nextPage)}
        onShowLess={showLess}
        onLoadMore={loadMore}
        loadMoreLoading={nextPageLoading}
      >
        {indulgenceCards}
      </PaginatedList>
    </section>
  );
};

export default Indulgences;
