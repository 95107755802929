import { useState } from "react";
import "./FaqQuestion.scss";

export type FaqQuestionProps = {
  question: string;
};

const FaqQuestion = (props: React.PropsWithChildren<FaqQuestionProps>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-question">
      <div className={`faq-button ${isOpen ? "faq-active" : ""}`} onClick={() => setIsOpen(!isOpen)}>
        <h3 className="faq-button-h3">{props.question}</h3>
      </div>
      <div className={`faq-panel ${isOpen ? "faq-panel-active" : ""}`}>{props.children}</div>
    </div>

    // <div className="tab">
    //   <input type="checkbox" id="chck1" />
    //   <label className="tab-label" htmlFor="chck1">
    //     {props.question}
    //   </label>
    //   <div className="tab-content">{props.answer}</div>
    // </div>
  );
};

export default FaqQuestion;
