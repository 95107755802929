import { TopSmall } from "components";

import "./Error.scss";

const Error = () => {
  return (
    <>
      <TopSmall />
      <div className="error container centered reverse-colors">
        <h1>Nutiko nenumatyta klaida :/</h1>
        <h2>Bandykite dar kartą</h2>
      </div>
    </>
  );
};

export default Error;
