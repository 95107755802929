import { Indulgence, OrderItem } from "utils";
import { Button } from "components/UI";
import { addBasketItem, selectBasketID, useAppDispatch, useAppSelector } from "state";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./IndulgenceCard.scss";
import { Api, useApiCall } from "api";

export type IndulgenceCardProps = {
  indulgence: Indulgence;
};

const IndulgenceCard = (props: IndulgenceCardProps) => {
  const dispatch = useAppDispatch();
  const basketID = useAppSelector(selectBasketID);
  const [added, setAdded] = useState(false);
  const { makeRequest: addOrderItem } = useApiCall<OrderItem>();

  const handleAddToBasketClick = () => {
    if (added) {
      return;
    }
    setAdded(true);
    setTimeout(() => {
      setAdded(false);
    }, 500);
    dispatch(addBasketItem(props.indulgence));

    if (basketID) {
      addOrderItem(
        Api.ENDPOINTS.ORDER_ITEM_CREATE(basketID),
        {},
        { indulgence_id: props.indulgence.id },
        Api.METHODS.POST
      );
    }
  };

  function createMarkup() {
    return { __html: props.indulgence.logo };
  }

  return (
    <div className="indulgence-card">
      <div className="indulgence-logo">
        <div dangerouslySetInnerHTML={createMarkup()}></div>
      </div>

      <h2>{props.indulgence.name}</h2>

      <div className="indulgence-card-content">
        <p>{props.indulgence.short_description}</p>
      </div>

      <div className="indulgence-card-price">
        <p>€{props.indulgence.price.toFixed(2)}</p>
      </div>

      <Button className={`basket-btn ${added ? "added" : ""}`} onClick={handleAddToBasketClick}>
        <FontAwesomeIcon icon={icon({ name: "check" })} className={`added-icon ${added ? "added" : ""}`} />Į krepšelį
      </Button>
    </div>
  );
};

export default IndulgenceCard;
