import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Order, OrderItem, Indulgence } from "utils";
import { RootState } from "./store";

const initialState: Order = {
  id: "",
  email: "",
  items: [],
  total: 0,
  itemAdded: false,
};

const basketSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearBasket: (state) => {
      return { ...initialState };
    },
    addBasketItem: (state, action: PayloadAction<Indulgence>) => {
      const basketItem: OrderItem = {
        id: 0,
        indulgence: action.payload,
        recipient: "",
      };
      state.items.push(basketItem);
      state.itemAdded = true;
    },
    updateBasket: (state, action: PayloadAction<Order>) => {
      const items = [...action.payload.items];
      return { ...action.payload, itemAdded: false, items: items };
    },
    removeBasketItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((obj) => {
        return obj.indulgence.id !== action.payload;
      });
      state.itemAdded = false;
    },
  },
});

export const { clearBasket, addBasketItem, removeBasketItem, updateBasket } = basketSlice.actions;

export const selectIsBasketAvailable = (state: RootState) => state.basket.items.length > 0;
export const selectBasket = (state: RootState) => state.basket;
export const selectBasketID = (state: RootState) => state.basket.id;

export default basketSlice.reducer;
