export const DEFAULT_REQUEST_ERROR = "Something went wrong, please try again. (500)";

export const InputErrors = {
  EMPTY_EMAIL: "Nurodykite taisyklingą el. paštą",
  EMPTY_RECIPIENT: "Nurodykite indulgencijos gavėjo vardą",
  EMPTY_NAME: "Nurodykite savo vardą",
  EMPTY_MESSAGE: "Pateikite savo žinutę",
  INCORRECT_EMAIL: "Pateikite tinkamą el. paštą",
  RECAPTCHA: "Patvirtinkite, kad jūs ne robotas",
  PROMO_CODE_EMPTY: "Nurodykite promo kodą",
  PROMO_CODE_USED: "Šiuo metu galima pritaikyti tik vieną promo kodą vienam pirkimui",
  PROMO_CODE_NOT_FOUND: "Kodas neegzistuoja arba jau panaudotas",
  PROMO_CODE_USE_ERROR: "Kodas negali būti pritaikytas jokiems pasirinkimams šiame krepšelyje",
  MAX_LENGTH: (length: any) => `Laukas negali būti ilgesnis. Max (${length})`,
};

export enum PromoType {
  FREE_ITEM = 0,
  FREE_ANYTHING = 1,
  DISCOUNT = 2,
  DISCOUNT_PERCENTAGE = 3,
}
