import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import ContactForm from "./ContactForm/ContactForm";
import { useState } from "react";
import { Link } from "react-router-dom";
import { config } from "api";

import "./Contacts.scss";

const Contacts = () => {
  const [linkCopied, setLinkCopied] = useState(false);

  return (
    <section id="contacts" className="section">
      <h2>Susisiekite</h2>
      <div className="contacts-container">
        <div className="contacts-form">
          <ContactForm />
        </div>
        <div className="contacts-data">
          {linkCopied && <span className="clipboard-msg">El. paštas nukopijuotas!</span>}
          <div
            className="contact copy-clipboard"
            onClick={() => {
              navigator.clipboard.writeText("SielosValymas@outlook.com");
              setLinkCopied(true);
            }}
          >
            <div className="contact-icon">
              <FontAwesomeIcon icon={icon({ name: "envelope" })} className="contacts-icon" />
            </div>
            <div className="contact-details">
              <p className="email">SielosValymas@outlook.com</p>
            </div>
          </div>
        </div>
      </div>
      <Link to={config.APP_PATHS.TERMS} className="credit-terms">
        Taisyklės ir privatumo politika
      </Link>
    </section>
  );
};

export default Contacts;
