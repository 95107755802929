import { Contacts, Credits, Header, Indulgences, Info, Top } from "components";
import Faq from "components/Faq/Faq";
import CookieConsent from "react-cookie-consent";

import "./Home.scss";

const Home = () => {
  return (
    <>
      <Top />
      <Header />
      <Info />
      <Indulgences />
      <Faq />
      <Contacts />
      <Credits />
      <CookieConsent
        buttonText="Suprantu"
        disableStyles={true}
        containerClasses="cookie-consent-body"
        contentClasses="cookie-consent-content"
        buttonClasses="cookie-consent-btn"
      >
        Ši svetainė naudoja slapukus siekiant užtikrinti geriausią vartotojo patirtį.
      </CookieConsent>
    </>
  );
};

export default Home;
