import Logo from "components/Logo/Logo";
import { Link } from "react-router-dom";
import { config } from "api";

import "./TopSmall.scss";

const TopSmall = () => {
  return (
    <>
      <div className="small-top">
        <div className="container-centered">
          <Link to={config.APP_PATHS.MAIN}>
            <Logo className="small-top-logo" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopSmall;
