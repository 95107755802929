import "./ListShowLess.scss";

export type ListShowLessProps = {
  onClick: () => void;
  listTopInView: boolean;
  listInView: boolean;
  listBottomInView: boolean;
};

const ListShowLess = (props: ListShowLessProps) => {
  let classes = "list-show-less";
  if (props.listTopInView || props.listInView === false) {
    classes += " list-show-less-hide";
    if (window.scrollY > 2000) {
      classes += " list-show-less-hide-f";
    }
  }
  if (props.listBottomInView) {
    classes += " list-show-less-static";
  }

  return (
    <>
      <div className={classes} onClick={props.onClick}>
        Rodyti mažiau
      </div>
    </>
  );
};

export default ListShowLess;
