import { ErrorMessage, Loader } from "components";
import React from "react";

import "./Form.scss";

type FormProps = {
  onSubmit: any;
  submitButtonName: string;
  isLoading: boolean;
  errorText: string;
  children?: any;
  childrenAfterSubmitButton?: any;
};

const Form = React.forwardRef((props: FormProps, ref: React.LegacyRef<HTMLFormElement>) => {
  return (
    <div className="ui-form-div">
      <form autoComplete="off" className="ui-form" onSubmit={props.onSubmit} ref={ref}>
        {props.children}

        <button type="submit" className="submit-button">
          {props.isLoading ? <Loader size="100%" /> : props.submitButtonName}
        </button>

        <ErrorMessage errorMessage={props.errorText} className="centered" />

        {props.childrenAfterSubmitButton}
      </form>
    </div>
  );
});

export default Form;
