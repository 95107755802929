import { useEffect } from "react";
import {
  clearBasket,
  selectBasket,
  selectIsBasketAvailable,
  updateBasket,
  useAppDispatch,
  useAppSelector,
} from "state";
import { ButtonLoader } from "components";
import { useNavigate } from "react-router-dom";
import { Api, config, useApiCall } from "api";
import { Order, OrderID, STORAGE_KEYS, getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./Header.scss";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const IsBasketAvailable = useAppSelector(selectIsBasketAvailable);
  const basket = useAppSelector(selectBasket);
  const orderID = getFromLocalStorage(STORAGE_KEYS.BASKET_ID);

  const {
    makeRequest: createOrder,
    loading: createOrderLoading,
    response: createOrderResponse,
  } = useApiCall<OrderID>();
  const { makeRequest: getOrder, response: orderResponse, error: orderError } = useApiCall<Order>();

  useEffect(() => {
    if (orderID) {
      getOrder(Api.ENDPOINTS.ORDER_GET_UPDATE(orderID));
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderResponse?.data) {
      dispatch(updateBasket(orderResponse.data));
    } else if (orderResponse?.status && orderResponse.status !== 200) {
      removeFromLocalStorage(STORAGE_KEYS.BASKET_ID);
      dispatch(clearBasket());
    } else if (orderError) {
      removeFromLocalStorage(STORAGE_KEYS.BASKET_ID);
      dispatch(clearBasket());
    }
  }, [orderResponse, orderError]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Navigate to basket only after update is successful
    if (createOrderResponse && createOrderResponse?.data.id !== "") {
      const newBasket = { ...basket, id: createOrderResponse?.data.id };
      setToLocalStorage(STORAGE_KEYS.BASKET_ID, newBasket.id);
      dispatch(updateBasket(newBasket));
      navigate(config.APP_PATHS.BASKET);
    }
    // TODO maybe do something extra in case it fails
  }, [createOrderResponse]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleBasketClick = () => {
    if (createOrderLoading) {
      return;
    }

    if (basket.id === "") {
      const data = basket.items.map((item) => item.indulgence.id);
      createOrder(Api.ENDPOINTS.ORDER_CREATE, {}, { indulgences: data }, Api.METHODS.POST);
    } else {
      navigate(config.APP_PATHS.BASKET);
    }
  };

  return (
    <nav className={`header ${IsBasketAvailable ? "basket-loaded" : ""}`}>
      <ul className="header-ul">
        <li>
          <a href="#info">
            <div className="header-mobile">
              <FontAwesomeIcon title="Apie" icon={icon({ name: "book" })} />
            </div>
            <div className="header-normal">Apie</div>
          </a>
        </li>
        <li>
          <a href="#indulgences">
            <div className="header-mobile">
              <FontAwesomeIcon title="Indulgencijos" icon={icon({ name: "list" })} />
            </div>
            <div className="header-normal">Indulgencijos</div>
          </a>
        </li>
        <li>
          <a href="#faq">
            <div className="header-mobile">
              <FontAwesomeIcon title="D.U.K." icon={icon({ name: "question" })} />
            </div>
            <div className="header-normal">D.U.K.</div>
          </a>
        </li>
        <li>
          <a href="#contacts">
            <div className="header-mobile">
              <FontAwesomeIcon title="Kontaktai" icon={icon({ name: "envelope" })} />
            </div>
            <div className="header-normal">Kontaktai</div>
          </a>
        </li>
        <div className={`basket ${IsBasketAvailable ? "basket-show" : ""}`}>
          <ButtonLoader className="basket-btn" isLoading={createOrderLoading} onClick={handleBasketClick}>
            <div className="header-mobile">
              <FontAwesomeIcon title="Indulgencijos" icon={icon({ name: "shopping-basket" })} />
            </div>
            <div className="header-normal">Krepšelis ({basket.items.length})</div>
          </ButtonLoader>
        </div>
      </ul>
    </nav>
  );
};

export default Header;
