const regexEmailValidation = /^[\w-.]+@([\w-]+\.)+[\w-]{1,}$/i;
const regexPasswordValidation =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,255}$/i;

export const validateInputNotEmpty = (input: string | undefined) => {
  if (input === undefined || input.trim() === "") {
    return false;
  }
  return true;
};

export const validateEmail = (input: string | undefined) => {
  if (input === undefined || regexEmailValidation.test(input) === false) {
    return false;
  }
  return true;
};

export const validatePassword = (input: string | undefined) => {
  if (input === undefined || regexPasswordValidation.test(input) === false) {
    return false;
  }
  return true;
};

export const validatePasswordConfirmation = (password: string | undefined, confirmPassword: string | undefined) => {
  if (password === undefined || confirmPassword === undefined || password !== confirmPassword) {
    return false;
  }
  return true;
};
