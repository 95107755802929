import "./Info.scss";

const Info = () => {
  return (
    <section id="info" className="section">
      <div className="container">
        <h1>Greita ir linksma <span className="accent">dovana</span> kiekvienam!</h1>
        <h1>Pradžiugink save, draugą ar kolegą švarios sąžinės <span className="accent">sertifikatu</span>!</h1>
        <p>
          <span className="accent">Indulgencija</span> - tai daugiau nei dovana. Tai galimybė palikti nuodėmes
          praeityje, išvalyti sąžinę ir pasinerti į naują gyvenimo etapą. Leisk sau ar savo draugams nuridenti akmenis
          nuo širdies ir pradėti iš naujo su indulgencijos sertifikatu!
        </p>
        <h3>
          Pasitenkinimas vos per <span className="accent">3 minutes!</span>
        </h3>
      </div>
    </section>
  );
};

export default Info;
