import React, { useEffect, useState } from "react";
import { ErrorMessage } from "..";
import { InputErrors } from "utils";

import "./StateInput.scss";

export type InputValidation = {
  Function: (input: string | undefined) => boolean;
  ErrorMessage: string;
};

export type InputProps = {
  name: string;
  value?: string;
  max_length: number;
  validations?: InputValidation[];
  onChange?: (value: string | undefined) => void;
  onBlur?: (value: string | undefined) => void;
  type?: string;
  children?: any;
  autoComplete?: string;
  forceValidation?: boolean;
  isRequired?: boolean;
};

const Input = (props: InputProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(props.value);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrorMessage("");
    if (props.validations && (props.isRequired || value)) {
      // Do validations only if field is required or value is not empty
      props.validations.forEach((validation) => {
        if (validation.Function(e.currentTarget.value) === false) {
          setErrorMessage(validation.ErrorMessage);
          return;
        }
      });
    }

    if (props.onBlur) {
      props.onBlur(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    let value = e.currentTarget.value;

    if (value.length > props.max_length) {
      value = e.currentTarget.value.slice(0, props.max_length);
      setErrorMessage(InputErrors.MAX_LENGTH(props.max_length));
    }

    setValue(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  useEffect(() => {
    if (props.forceValidation && props.validations) {
      props.validations.forEach((validation) => {
        if (validation.Function(value) === false) {
          setErrorMessage(validation.ErrorMessage);
          return;
        }
      });
    }
  }, [props.forceValidation, props.validations]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="input">
        <input
          placeholder=" "
          autoComplete={props.autoComplete}
          id={props.name}
          type={props.type}
          className={errorMessage ? "input-error" : ""}
          onBlur={handleOnBlur}
          onChange={handleChange}
          value={value}
        />
        <label htmlFor={props.name}>{props.name}</label>
        {props.children}
      </div>

      <ErrorMessage errorMessage={errorMessage} />
    </>
  );
};

Input.defaultProps = {
  isRequired: true,
};

export default Input;
