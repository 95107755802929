import { AxiosResponse, AxiosError } from "axios";
import { useState } from "react";
import { Api } from "./api";
import { config } from "./config";
import { useNavigate } from "react-router-dom";

export type BaseErrorResponse =
  | {
      non_field_errors?: string[];
    }
  | string[];

const ParseBaseResponse = (response: BaseErrorResponse | undefined): string | undefined | null => {
  const array = Array.isArray(response) ? response : response?.non_field_errors;
  return array?.join(", ") || null;
};

export function useApiCall<T = any>() {
  const navigate = useNavigate();
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const makeRequest = (endpoint: string, queryParams: {} = {}, body: {} = {}, method: string = Api.METHODS.GET) => {
    setResponse(null);
    setError(null);
    setLoading(true);
    Api.request(endpoint, queryParams, body, method)
      .then((res) => {
        setResponse(res);
      })
      .catch((axiosError: AxiosError<BaseErrorResponse>) => {
        const errorMessage = ParseBaseResponse(axiosError.response?.data) || axiosError.message;
        setError(errorMessage);
        if (config.LOG_ERRORS) {
          console.log(errorMessage, new Date().toString());
        }
        if (config.AUTO_REDIRECT_500 && axiosError.response?.status === 500) {
          navigate(config.APP_PATHS.ERROR);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { makeRequest, loading, response, error };
}
