import { Api, useApiCall } from "api";
import { ButtonLoader, ErrorMessage, StateInput, SuccessMessage } from "components";
import { useEffect, useState } from "react";
import { InputErrors, Order, PromoResponse, PromoType } from "utils";

import "./Promo.scss";

type PromoProps = {
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;
};

const Promo = (props: PromoProps) => {
  const [promoCode, setPromoCode] = useState<string | undefined>();
  const [promoForceValidtion, setPromoForceValidtion] = useState(false);
  const [promoError, setPromoError] = useState("");

  const {
    makeRequest: checkPromo,
    loading: checkPromoLoading,
    error: checkPromoError,
    response: checkPromoResponse,
  } = useApiCall<PromoResponse>();

  const validatePromoApply = (promo: PromoResponse) => {
    if (promo.type === PromoType.FREE_ITEM && promo.item_id === undefined) {
      return false;
    }
    return true;
  };

  const applyPromo = (promo: PromoResponse) => {
    const order: Order = { ...props.order };
    promo.amount = promo.amount ? promo.amount : 0;
    switch (promo.type) {
      case PromoType.DISCOUNT: {
        order.total = Math.max(order.total - promo.amount, 0);
        break;
      }
      case PromoType.DISCOUNT_PERCENTAGE: {
        order.total = Math.floor(order.total - (order.total * promo.amount) / 100);
        break;
      }
      case PromoType.FREE_ANYTHING: {
        if (order.items.length > 0) {
          order.total -= order.items[0].indulgence.price;
          order.items[0].indulgence.price = 0;
        }
        break;
      }
      case PromoType.FREE_ITEM: {
        order.items.forEach((item) => {
          if (item.id === (promo.item_id as number)) {
            order.total -= item.indulgence.price;
            item.indulgence.price = 0;
            return;
          }
        });
        break;
      }
    }
    order.promo = promoCode;
    props.setOrder(order);
  };

  useEffect(() => {
    if (checkPromoError === null && checkPromoResponse === null) {
      return;
    }

    if (checkPromoError) {
      setPromoError(InputErrors.PROMO_CODE_NOT_FOUND);
      return;
    }
    if (checkPromoResponse === null || validatePromoApply(checkPromoResponse.data) === false) {
      setPromoError(InputErrors.PROMO_CODE_USE_ERROR);
      return;
    }
    applyPromo(checkPromoResponse.data);
  }, [checkPromoError, checkPromoResponse]); //eslint-disable-line react-hooks/exhaustive-deps

  const validatePromoUsed = (_input: string | undefined) => {
    return props.order.promo ? false : true;
  };

  const validatePromoEmpty = (input: string | undefined) => {
    return input ? true : false;
  };

  const promoValidations = [
    { Function: validatePromoUsed, ErrorMessage: InputErrors.PROMO_CODE_USED },
    { Function: validatePromoEmpty, ErrorMessage: InputErrors.PROMO_CODE_EMPTY },
  ];

  const validatePromo = () => {
    let isValid = true;
    promoValidations.forEach((validation) => {
      if (validation.Function(promoCode) === false) {
        setPromoForceValidtion(true);
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  const handlePromoCheck = () => {
    setPromoError("");
    setPromoForceValidtion(false);
    if (validatePromo() === false) {
      return;
    }
    checkPromo(Api.ENDPOINTS.ORDER_PROMO_CHECK(props.order.id, promoCode as string));
  };

  if (props.order.promo) {
    return <SuccessMessage message="Promo kodas pritaikytas!" />;
  }

  return (
    <div className="checkout-form-promo-container">
      <div className="checkout-form-promo">
        <StateInput
          name={"Nuolaidos kodas"}
          max_length={250}
          onChange={(value) => {
            setPromoError("");
            setPromoCode(value);
          }}
          onBlur={() => {
            setPromoForceValidtion(false);
            setPromoError("");
          }}
          validations={promoValidations}
          forceValidation={promoForceValidtion}
          autoComplete="off"
          isRequired={false}
          value={""}
        ></StateInput>
        {promoError && <ErrorMessage errorMessage={promoError} />}
        <ButtonLoader onClick={handlePromoCheck} isLoading={checkPromoLoading}>
          Pritaikyti
        </ButtonLoader>
      </div>
    </div>
  );
};

export default Promo;
