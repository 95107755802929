import { config } from "api";
import { Accept, Cancel, Checkout, Error, Home, Terms } from "pages";
import { Navigate, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Routes>
      <Route index path={config.APP_PATHS.MAIN} element={<Home />} />
      <Route path={config.APP_PATHS.BASKET} element={<Checkout />} />
      <Route path={config.APP_PATHS.ORDER_ACCEPTED} element={<Accept />} />
      <Route path={config.APP_PATHS.ORDER_CANCELLED} element={<Cancel />} />
      <Route path={config.APP_PATHS.TERMS} element={<Terms />} />
      <Route path={config.APP_PATHS.ERROR} element={<Error />} />

      <Route path="*" element={<Navigate to={config.APP_PATHS.MAIN} />} />
    </Routes>
  );
};

export default App;
