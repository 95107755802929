import "./ErrorMessage.scss";

export type ErrorMessageProps = {
  className?: string;
  errorMessage?: string | null;
};

const ErrorMessage = (props: ErrorMessageProps) =>
  props.errorMessage && <div className={`error-message ${props.className && props.className }`}>{props.errorMessage}</div>;

export default ErrorMessage;
